<template>
  <div id="v-pills-personal" role="tabpanel" aria-labelledby="v-pills-personal-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Personal details
        <span class="edit-button" @click.prevent="edit_profile=!edit_profile"><img :src="$S3_url+'/Assets/images/edit-icon-black.svg'" alt="edit profile" title="edit profile" width="25"></span>
      </h5>
      <hr />
      <div class="p-4" id="display_profile" v-if="!edit_profile">
        <div class="row" v-if="user_details">
          <div class="col-4">
            <label class="text-secondary">About me</label>
          </div>
          <div class="col-8">
            <p v-if="user_details.resume_summary" style="word-wrap:break-word">{{ user_details.resume_summary }}</p>
            <p v-if="!user_details.resume_summary">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="text-secondary">Name<span style="color: red">*</span></label>
          </div>
          <div class="col-8"><p>{{ this.e_user.name }}</p></div>
        </div>
        <div v-if="user_details">
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Current city<span style="color: red">*</span></label>
            </div>
            <div class="col-8">
              <p v-if="user_details.user_city">{{user_details.user_city.city_name}}</p>
              <p v-if="!user_details.user_city">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Sub location</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.sub_location">{{user_details.sub_location.sublocation_name}}</p>
              <p v-if="!user_details.sub_location">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Gender</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.gender">{{user_gender}}</p>
              <p v-if="!user_details.gender">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Date of birth</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.dob">{{user_details.dob.split('-').reverse().join('-')}}</p>
              <p v-if="!user_details.dob">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Marital Status</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.marital_status">{{ user_marital_status }}</p>
              <p v-if="!user_details.marital_status">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Languages known<span style="color: red">*</span></label>
            </div>
            <div class="col-8">
              <p v-if="this.user.languages_known">
                {{ JSON.parse(this.user.languages_known.language_known).join()}}
              </p>
              <p v-if="!this.user.languages_known">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Job role<span style="color: red">*</span></label>
            </div>
            <div class="col-8">
              <p v-if="user_career.fst_prefered_role"><span>{{user_role.join()}}</span></p>
              <p v-if="!user_career.fst_prefered_role">-</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Skill<span style="color: red">*</span></label>
            </div>
            <div class="col-8">
              <p v-if="user_details.skills">{{JSON.parse(user_details.skills)}}</p>
              <p v-if="!user_details.skills">-</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Current Address</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.current_address">{{ user_details.current_address }}</p>
              <p v-if="!user_details.current_address">-</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Permanent Address</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.permanent_address">{{ user_details.permanent_address }}</p>
              <p v-if="!user_details.permanent_address">-</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Alternate Contact Number</label>
            </div>
            <div class="col-8">
              <p v-if="user_details.contact_no">{{ user_details.contact_no }}</p>
              <p v-if="!user_details.contact_no">-</p>
            </div>
          </div>
        </div>
      </div>
      <div id="edit_profile" v-if="edit_profile">
        <v-form ref="personal_form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-lg-4">
              <label>About me</label>
            </div>
            <div class="col-lg-8">
              <v-textarea id="title" v-model="e_user.resume_summary" :rules="titleRules" placeholder="Describe yourself in max 120 characters" cols="30" rows="5" outlined counter="120"></v-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Name<span style="color: red">*</span></label>
            </div>
            <div class="col-lg-8">
              <v-text-field v-model="e_user.name" type="text" label="Name" :rules="[v => !!v || 'Select name!']" outlined></v-text-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Current city<span style="color: red">*</span></label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete v-model="e_user.city" label="Current city" :items="states" item-text="text" item-value="id" :rules="[v => !!v || 'Select city!']" @change="showSubcity" clearable outlined deletable-chips> 
                <template slot="item" slot-scope="data">
                  <template v-if = typeof(data.item) != 'object'>
                    <v-list-item-content v-text="data.item.text"/>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"/>
                      <v-list-item-sub-title v-html="data.item.group"/>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <div class="row" v-show="subCity">
            <div class="col-lg-4">
              <label>Sub location</label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete v-model="e_user.sub_location" label="Sub location" :items="sublocations" item-value="value" item-text="text" clearable outlined deletable-chips></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Gender</label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete name="gender" v-model="e_user.gender" :items="gender.options" item-text="text" item-value="value" label="Gender" clearable outlined deletable-chips></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Date of Birth</label>
            </div>
            <div class="col-lg-8">
              <input class="form-control fields" v-model="e_user.dob" label="Date of birth" type="date" name=""/>
            </div>
          </div>
          
          <div class="row">
            <div class="col-lg-4 form-group">
              <label>Marital Status</label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete name="marital_status" v-model="e_user.marital_status" :items="maritalStatus.options" item-text="text" item-value="value"  label="Marital Status" clearable outlined></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Languages Known<span style="color: red">*</span></label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete v-model="e_user.languages_known" :items="languages" item-text="text" item-value="text" :rules="[v => !!v.length || 'Select languages!']" label="Languages known" deletable-chips clearable outlined chips multiple></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Jobrole<span style="color: red">*</span></label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete v-model="e_user.jobrole" label="Jobrole " :rules="[v => !!v.length || 'Select jobrole!']" :items="jobrole" item-text="text" item-value="value" multiple deletable-chips chips clearable outlined></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Skills<span style="color: red">*</span></label>
            </div>
            <div class="col-lg-8">
              <v-autocomplete id="label-experience" label="Skills" v-model="e_user.skils" :items="skills" item-text="text" item-value="text" :rules="[v => !!v.length || 'Select skills!']" clearable outlined multiple chips deletable-chips></v-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Current address</label>
            </div>
            <div class="col-lg-8">
              <v-textarea id="current_address" label="Current address" v-model="e_user.current_address" cols="30" rows="5" outlined></v-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Permanent address</label>
            </div>
            <div class="col-lg-8">
            <v-textarea id="permenant_address" v-model="e_user.permanent_address" label="Permanent address" cols="30" rows="5" outlined></v-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <label>Alternate contact number</label>
            </div>
            <div class="col-lg-8">
              <v-text-field name="contact-number" label="Alternate contact number" type="tel" v-model="e_user.alternate_contact_no" outlined></v-text-field>
            </div>
          </div>
          <div class="error-feedback" v-if="error">{{err_msg}}</div>
          <div class="row">
            <div class="col-md-12">
              <button class="float-right btn btn-primary" v-if="!loading" @click="updateProfile" variant="primary">Save</button>
              <button class="float-right btn btn-primary" v-if="loading" variant="primary">...</button>
            </div>
          </div>
        </v-form>
      </div>
      <hr />

      <h5 class="text-primary p-3">Resume<span class="float-right" style="font-size: 14px">
        <button class="btn btn-primary" v-on:click="resumeEdit=!resumeEdit">Edit</button>
        </span>
      </h5>
      <hr />
      <form>
        <div class="p-4" v-if="!resumeEdit&&user_details">
          <div class="row mb-2">
            <div class="col-lg-4">
              <label class="text-secondary">Uploaded resume</label>
            </div>
            <div class="col-lg-8">
              <p v-show="user_details.resume_path"><a class="mr-4" target="_blank" :href="$S3_bucket_url+user_details.resume_path">Download</a><a class="text-primary" @click="copyToClipboard($S3_bucket_url+user_details.resume_path)">Copy link</a></p>
              <p v-show="!user_details.resume_path">-</p>
            </div>
          </div>
        </div>

        <div class="p-4" v-if="resumeEdit">
          <div class="row mb-2">
            <div class="col-lg-6">
              <v-file-input  v-model="resume" placeholder="Post resume" :append-icon="$icons.file" prepend-icon="" show-size outlined accept=".doc,.docx,.rtf,.pdf"></v-file-input>
              <br />
              <p class="mb-0" style="font-size:10px;">doc, docx, rtf, pdf</p>
            </div>
            <div class="error-feedback" v-if="resume_error">{{resume_err_msg}}</div>
            <div class="col-lg-2">
              <button class="float-right btn btn-primary" @click.prevent="updateResume()">Save</button>
            </div>
          </div>
        </div>
      </form>
      <hr />
    </div>
    <b-modal id="update-success" ref="update-success" size="sm" title=" " body-bg-variant="primary" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Profile updated</div>
    </b-modal>
  </div>
</template>

<script>
import AwsUpload from "../../utils/s3upload"
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
  name: "PersonalDetails",
  props: ["user"],
  data() {
    return {
      user_details: this.user?.personal_details,
      user_career:this.user?.careerPreferencesDetail,
      user_city : null,
      user_state : null,
      languages:[],
      skills:[],
      jobrole:[],
      u_jobrole:[],
      t_city:null,
      gender:{
        options:[
          {value:1, text:'Male'},
          {value:2, text:'Female'},
          {value:3, text:'Both'}
        ]
      },
      maritalStatus:{
        options:[
          {value:1, text:'Single'},
          {value:2, text:'Married'}
        ]
      },
      communication_rate:{
        options:[
          {value:1, text:'Low'},
          {value:2, text:'Moderate'},
          {value:3, text:'Good'},
          {value:4, text:'Very Good'}          
        ]
      },
      e_user:{
        resume_summary:'',
        name : null,
        city : null,
        sub_location : null,
        gender : null,
        dob : null,
        marital_status : null,
        languages_known : [],
        communication_rating : 1,
        jobrole: [],
        skils: [],
        current_address: null,
        permanent_address: null,
        alternate_contact_no: null
      },
      user_gender:null,
      user_marital_status:null,
      user_role:[],
      limit_error:false,
      error_msg:'',
      subCity: false,
      can_update: 0 ,
      states:[],
      sublocations:[],
      resumeEdit: false,
      resume: null,
      update:{
        resume:null
      },
      action: new Actions(),
      validate: new validation(),
      resume_loading: false,
      profile_loading: false,
      valid:true,
      loading: false,
      edit_profile:false,
      titleRules: [
        v => !!v || 'Enter valid email', 
        v => (v && v.length <= 120) || 'Max 120 characters'
      ],
      error:false,
      err_msg:null,
      resume_error:false,
      resume_err_msg:null,
    };
  },
  mounted(){
    // this.e_user.sub_location = this.user_details.sub_location.sublocation_id
    this.user_city = this.user_details?.user_city?.city_id
    // this.subCity = (this.user_details.sub_location.sublocation_name) ? true:false
    // document.getElementById('current_address').text = this.e_user.current_address?this.e_user.current_address:' ',
    // document.getElementById('permanent_address').text = this.e_user.permanent_address?this.e_user.permanent_address:' '
  },
  beforeMount(){
    this.getLanguages();
    this.getSkills();
    this.getJobrole();
    this.getStates();
    this.setUserDetails();
  },
  methods: {
    setUserDetails(){
      this.e_user.name = this.user.name;
      this.e_user.resume_summary = this.user_details?.resume_summary;
      this.e_user.city = this.user_details?.user_city.city_id;
      if(this.e_user.city){
        this.getCities();
      }
      if(this.user_details?.sub_location){
        this.e_user.sub_location = this.user_details?.sub_location.sublocation_id;
      }
      this.e_user.gender = this.user_details?.gender;
      if(this.e_user.gender != null){
        this.getGender(this.e_user.gender);
      }
      this.e_user.dob = this.user_details?.dob;
      this.e_user.marital_status = this.user_details?.marital_status;
      if(this.e_user.marital_status != null){
        this.getMaritalStatus(this.e_user.marital_status);
      }
      if(this.user.languages_known){
        this.e_user.languages_known = JSON.parse(this.user.languages_known.language_known);
      }
      if(this.user_career?.fst_prefered_role){
        this.setJobRole();
      }
      if(this.user_details?.skills){
        var skills = this.user_details.skills.split(',');
        skills = (JSON.parse(skills).replace(/[^a-zA-Z0-9, ]/g, " ")).split(',');
        skills.forEach((skill) => {
          this.e_user.skils.push(skill.trim());
        })
      }
      this.e_user.current_address = this.user_details?.current_address ;
      this.e_user.permanent_address = this.user_details?.permanent_address;
      this.e_user.alternate_contact_no = this.user_details?.contact_no;
    },
    async copyToClipboard(link){
      await navigator.clipboard.writeText(link);
    },
    EditProfile: function () {
      var display_profile = document.getElementById('display_profile')
      var edit_profile = document.getElementById('edit_profile')

      display_profile.style.display= (display_profile.style.display=='none')? 'block' : 'none';
      edit_profile.style.display= (edit_profile.style.display=='none')? 'block' : 'none';
      // this.showSubcity();
      // this.e_user.sub_location=null;
      this.e_user.resume_summary = (this.user_details?.resume_summary)?(this.user_details?.resume_summary):'';
    },
    getStates: async function () {
      await this.action.getStates().then(async (data) => {
        this.states.push({header: 'Top Metropolitan Cities'})
        await data.states.forEach(async (element) => {
          await element.cities.forEach(city => {
            if(city.top_city == 1){
              this.states.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
            }
          });
          this.states.push({divider:true})
          this.states.push({header: element.state_name});
          element.cities.forEach(city => {
            if(city.top_city == 0){
              this.states.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:element.state_name})
            }
        });
      });
      });
    },
    showSubcity: function () {
      this.subCity = false;
      if (this.e_user.city != null) {
        this.getCities();
      }
    },
    getCities: function(){
      this.sublocations = [];
      this.action.getSubcity(this.e_user.city).then((data) => {
        if(data.sublocations){
          this.subCity = true;
          data.sublocations.forEach((element) => {
            this.sublocations.push({
              value: element.sublocation_id,
              text: element.sublocation_name,
            });
          });
        }
      });
    },
    getLanguages(){
        this.action.getLanguages().then(data => {
          data.languages.forEach((element) => {
            this.languages.push({
              value:element.id,
              text:element.language
            })
          })
        }).catch(err =>{
          console.log(err);
        })
    },
    getSkills: function(){
      this.action.getSkills().then(data => {
        data.skills.forEach((element) => {
          this.skills.push({
            value:element.id,
            text:element.skill
          })
        })
      }).catch(err =>{
        console.log(err);
      })
    },
    getJobrole: function(){
      this.action.getJobrole().then((data) => {
        data.jobroles.forEach((element) => {
          this.jobrole.push({
            value: element.role_id,
            text: element.role,
          });
        });
        // this.setUserJobrole();
      });
    },
    setJobRole: function(){
      this.$store.state.jobrole.jobrole.jobrole.forEach((element) => {
          if(element.value == this.user_career.fst_prefered_role){
            this.user_role.push(element.text)
          }
          if(element.value == this.user_career.snd_prefered_role){
            this.user_role.push(element.text)
          }
          if(element.value == this.user_career.trd_prefered_role){
            this.user_role.push(element.text)
          }
      })
        if(this.user_career.fst_prefered_role){
          this.e_user.jobrole.push(this.user_career.fst_prefered_role);
        }
        if(this.user_career.snd_prefered_role){
          this.e_user.jobrole.push(this.user_career.snd_prefered_role);
        }
        if(this.user_career.trd_prefered_role){
          this.e_user.jobrole.push(this.user_career.trd_prefered_role);
        }
    },
    getGender(id){
      this.gender.options.forEach((g) => {
        if(g.value == id){
          this.user_gender = g.text;
        }
      })
    },
    getMaritalStatus(id){
      this.maritalStatus.options.forEach((m) => {
        if(m.value == id){
          this.user_marital_status = m.text; 
        }
      })
    },
    limiter: function(e){
      if (e.length > 4) {
        this.limit_error = true;
        this.error_msg = "Select maximum 4 language known";
        e.pop();
      }else{
        this.limit_error = false;
        this.error_msg = ""
      }
    },
    updateProfile: function(e) {
      e.preventDefault();
      this.can_update = 0;
      this.loading= true;
      this.error = false;
      this.error_msg = null;

      this.valid = this.$refs.personal_form.validate();
      if (this.valid) {
          let token = this.$cookies.get("hire-seeker").token;
          this.e_user.skils = (this.e_user.skils)?((typeof this.e_user.skils!= 'string')?this.e_user.skils.join():this.e_user.ski):null;
          this.action.updateSeekerProfile(this.e_user,token).then(data =>{
            if(data.message == 'Personal Deatails Updated'){
              this.loading = false;
              this.$refs['update-success'].show();
              setTimeout(() =>{
                this.$refs['update-success'].hide();
                location.reload();
              },3000)
            }
          }).catch(err =>{
            console.log(err);
            this.loading = false;
            this.error = true;
            if(window.navigator.onLine){
              this.err_msg = "System error! Please try again.";
            }else{
              this.err_msg = "Network error! Please check your internet connection.";
            }
          })
      }else{
        this.loading = false;
        this.error = true;
        if(window.navigator.onLine){
          this.err_msg = "Fill all mandatory fields.";
        }else{
          this.err_msg = "Network error! Please check your internet connection.";
        }
      }
    },
    getResumePath: function(path) {
      if(path != null){
        let res = path.split("/");
        return res[res.length-1]
      }
    },
    updateResume: async function(){
      this.resume_error = false;
      this.resume_err_msg = null;
      
      let token = this.$cookies.get("hire-seeker").token;
      if(this.resume != null){
        const file = this.resume;
        const awsUploader = new AwsUpload();
          try {
            var extension = this.resume.name.split('.')[this.resume.name.split('.').length-1]
            var d = new Date();
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
            var timestamp = Math.floor(Date.now() / 1000);
            var filename = 'resume/'+ current_date+"/"+this.user.name + timestamp + "."+extension;
            const response = await awsUploader.uploadToS3(file, filename);
            this.update.resume = response.url;
            this.action.updateResume(this.update.resume,token).then(data =>{
              if(data.message == "Resume Updated"){
                this.resumeEdit = false;
                this.resume = false;
                let cookie = this.$cookies.get('hire-seeker');
                let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume:'yes',resume_path:this.update.resume,alert:cookie.alert};
                this.$cookies.remove("hire-seeker");
                this.$cookies.set("hire-seeker",updated_cookie);
                this.$refs['update-success'].show();
                setTimeout(() =>{
                  this.$refs['update-success'].hide();
                  location.reload();
                },3000)
              }else{
                this.resume_error = true;
                this.resume_err_msg = "System error! Please try again.";
              }
            }).catch(err =>{
              console.log(err);
              this.resume_error = true;
              if(window.navigator.onLine){
                this.resume_err_msg = "System error! Please try again.";
              }else{
                this.resume_err_msg = "Network error! Please check your internet connection.";
              }
            })
          } catch (error) {
            console.error('Failed to upload file to S3:', error);
          }
      }
    }
  },
};
</script>

<style scoped>
input{padding: 28px 0px 28px 15px !important;}.error-feedback{position: absolute;}v-text-field__details{display: block !important;}
</style>